<template>
    <div class="app-popup-warning">
        {{ text }}
    </div>
</template>

<script>
    export default {
        props: {
            text: {
                type: String,
                default: ""
            }
        }
    }
</script>

<style lang="scss" scoped>
    .app-popup-warning{
        max-width: 350px;
        text-align: center;
        padding: 2em;
        background-color: $error;
        color: $white;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 0.3em;
        border: 1px solid $white;
        z-index: 10000;
    }
</style>