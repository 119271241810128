<template>
    <div class="app-loading-animation">
        <div class="animation-item" :style="`transform: scale(${scaleOne})`"></div>
        <div class="animation-item" :style="`transform: scale(${scaleTwo})`"></div>
        <div class="animation-item" :style="`transform: scale(${scaleThree})`"></div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                scaleOne: 1,
                scaleTwo: 1,
                scaleThree: 1,
            }
        },
        methods: {
            scaling(){
                setInterval(() => {
                    this.scaleOne = 2;                   
                    setTimeout(() => {
                        this.scaleOne = 1;                        
                    }, 250)
                    setTimeout(() => {
                        this.scaleTwo = 2;                        
                    }, 250)
                    setTimeout(() => {
                        this.scaleTwo = 1;                        
                    }, 500)
                    setTimeout(() => {
                        this.scaleThree = 2;                        
                    }, 500)
                    setTimeout(() => {
                        this.scaleThree = 1;                        
                    }, 750)
                }, 1000)

            }
        },
        beforeMount(){
            this.scaling();
        }
    }
</script>

<style lang="scss" scoped>
    .app-loading-animation{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $accent-dark;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.4em;
    }
    .animation-item{
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: $white;
        margin: 0 1em;
        transition: 0.7s ease;
    }
</style>