<template>
    <div class="block name">
        <div class="old-info info">
            <span class="label" v-if="email">E-mail: {{ email }}</span>
            <AppEditIcon @action="editEmail" class="icon" v-if="email && !emailEditing" />                    
            <BaseCloseIcon class="icon" v-if="email && emailEditing" @action="closeEditEmail" />
        </div>
        <div class="new-info info" >
            <transition name="input">
            <BaseInput 
                outlined 
                rounded
                label="E-mail"
                width="320"
                id="email"
                validation="email"
                v-if="!email || emailEditing" @sendData="getNewEmail($event)"/>
            </transition>                    
            <transition name="input">
            <BaseSaveIcon class="icon" v-if="!email || emailEditing" @action="sendNewEmail" />
            </transition>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import AppEditIcon from "@/components/icons/app-edit-icon.vue";
    export default {
        components: {           
            AppEditIcon         
        },
        props: {
            email: {
                type: String,
                default: null
            }
        },
        setup(props, { emit }){
            const newEmail = ref("");
            const emailEditing = ref(false);
            const editEmail = () => emailEditing.value = true;
            const closeEditEmail = () => emailEditing.value = false;
            const getNewEmail = (data) => {
                newEmail.value = data;
            };
            const sendNewEmail = () => {
                if(newEmail.value.length > 0){
                    emit('sendNewEmail', { column: 'email', value: newEmail.value })
                } else {
                    return false;
                }
            }
   
            return{
                newEmail,
                emailEditing,
                editEmail,
                closeEditEmail,
                getNewEmail,
                sendNewEmail,
            }

        }
    }
</script>

<style lang="scss" scoped>
    .block{
        width: 100%;
        padding: 0.5em 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .info{
            margin: 1em 0;
        }
        .old-info{
            .label{
                margin-right: 1em;
                padding: 0.5em 0;
        }
            .icon{
                cursor: pointer;
            }
        }
        .new-info{
            display: flex;
            justify-content: space-around;
            align-items: center;
            .input-enter-active{
                transition: 0.3s ease;
            }
            .input-enter-from{
                opacity: 0;
            }
            .input-enter-to{
                opacity: 1;
            }
            .input-leave-active{
                transition: 0.3s ease;
            }
            .input-leave-from{
                opacity: 1;
            }
            .input-leave-to{
                opacity: 0;
            }
            .icon{
                cursor: pointer;
            }
        }
    }
</style>