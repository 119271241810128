import api from "./api.js";

export default {
    async getMassagistInfo(credentials){
        return api.post('massagist', credentials);
    },
    async getMassagistsInfoByParams(credentials){
        return api.post('massagists-by-params', credentials);
    },
    async getAllMassagistsByCity(credentials){
        return api.post('massagists-by-city', credentials);
    },
    async getNewMassagistsByCity(credentials){
        return api.post('get-new-massagists-by-city', credentials);
    },
    async getMassagistsByIDs(credentials){
        return api.post('get-massagists-by-ids', credentials)
    },
    async massagistUpdateData(credentials){
        return api.post('massagist-update', credentials)
    },
    async findMassagistsBySearchingOptions(credentials){
        return api.post('find-massagist-by-searching-options', credentials)
    },
    //РАБОТА С ФОТОГРАФИЯМИ
    async massagistUploadPhoto(credentials, data){
        return api.post('upload-massagist-photo', credentials, {
            headers: {
                id: data
            }
        })
    },
    async getAllMassagistPhotos(credentials){
        return api.post('get-all-massagist-photos', credentials)
    },
    async getPhotosForMultipleMassagists(credentials){
        return api.post('get-photos-for-multiple-massagists', credentials)
    },
    async setMassagistPhotoAsMain(credentials){
        return api.post('set-massagist-photo-as-main', credentials)
    },
    async deleteMassagistPhoto(credentials){
        return api.post('delete-massagist-photo', credentials)
    },
    // ВЕРИФИКАЦИЯ АККАУНТА
    async uploadPhotoForVerifying(credentials, data){
        return api.post('upload-massagist-photo-for-verifying', credentials, {
            headers: {
                id: data
            }
        })
    },
    async getVerifyingStatus(credentials){
        return api.post('get-verifying-status', credentials)
    },
    //РАБОТА С БАЛАНСОМ И СТАВКАМИ
    async getMassagistBalance(credentials){
        return api.post('get-massagist-balance', credentials)
    },
    async getMassagistBet(credentials){
        return api.post('get-massagist-bet', credentials)
    },
    async setMassagistBet(credentials){
        return api.post('set-massagist-bet', credentials)
    },
    async getMaxBetForCity(credentials){
        return api.post('get-max-bet-for-city', credentials)
    },
    async getBetsByIDs(credentials){
        return api.post('get-bets-by-ids', credentials)
    },
    async getBalancesByIDs(credentials){
        return api.post('get-massagists-balances', credentials)
    },

    //БЕЗОПАСНОСТЬ
    async setEmail(credentials){
        return api.post('set-massagist-email', credentials)
    },
    async setNewPassword(credentials){
        return api.post('set-massagist-new-password', credentials)
    },
}