const domain = {
    // base: 'http://localhost:8080',
    // massagistPhotosPath: 'http://localhost:8080/img/massagists/',
    // massagistServicePhotoPath: 'http://localhost:8080/img/service/',
    // pathToStatic: 'http://localhost:3001/'
    base: 'https://erm21.net',
    massagistPhotosPath: 'https://erm21.net/img/massagists/',
    massagistServicePhotoPath: 'https://erm21.net/img/service/',
    pathToStatic: 'https://erm21.net/'
};


export default domain;