<template>
    <div class="page-loading-animation">
        <div class="wrapper">
            <AppLoadingAnimation />
        </div>
    </div>
</template>

<script>
    import AppLoadingAnimation from "@/components/animations/app-loading-animation.vue";
    export default {
        components: {
            AppLoadingAnimation
        }
        
    }
</script>

<style lang="scss" scoped>
    .page-loading-animation{
        width: 100%;
        height: 100vh;
        position: fixed;
        background-color: rgba(255, 255, 255, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        z-index: 10000;
        .wrapper{
            width: 150px;
            height: 60px;
            position: relative;
        }
    }
</style>