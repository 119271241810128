<template>
    <div class="app-massagist-personal-page-safaty">
        <AppPageLoadingAnimation v-if="loading.isLoading" />
        <div class="add-email">
            <div class="input-form">
                <AppEmailForm :email="email.email" @sendNewEmail="email.getNewEmail($event)"/>
                <div class="email-comment">
                    Введите адрес электронной почты, чтобы иметь возможность восстановить пароль
                </div>                
            </div>
        </div>
        <div class="change-password">
            <div class="input-form">
                <h5>Сменить пароль</h5>
                <BaseInput 
                    rounded
                    outlined
                    label="ваш старый пароль"
                    id="oldPassword"
                    width="320"
                    margin="0 0 20px 0"
                    validation="password"
                    @sendData="password.getOldPassword($event)"
                    inputType="password" />
                <BaseInput 
                    rounded
                    outlined
                    label="ваш новый пароль"
                    id="newPassword"
                    width="320"
                    margin="0 0 20px 0"
                    validation="password"
                    @sendData="password.getNewPassword($event)"
                    inputType="password" />
                <BaseInput 
                    outlined
                    rounded
                    validation="repeate"
                    :password="password.newPassword"
                    id="repeatNewPassword"
                    label="Повторите пароль"
                    required
                    width="320"
                    margin="0 0 20px 0"
                    inputType="password"
                    @sendData="password.getRepeateNewPassword($event)" />
            <div class="drop-password" @click="dropPassword">Восстановить пароль?</div>
            </div>
            <div class="button">
                <AppRegularBtn text="сохранить" light @action="password.saveNewPassword" :disabled="!password.newPasswordRepeate" />
            </div>
            <AppPopupWarning :text="warning.text" v-if="warning.isActive" />
        </div>
    </div>
</template>

<script>
import AppRegularBtn from "@/components/buttons/app-regular-btn.vue";
import AppEmailForm from "@/components/forms/massagist-edit-forms/app-massagist-edit-email-form.vue";
import AppPopupWarning from "@/components/popups/app-popup-warning.vue";
import AppPageLoadingAnimation from "@/components/animations/app-page-loading-animation.vue";
import massagistApi from "@/api/massagist.js";
import { useStore } from "vuex";
import { reactive, onMounted } from 'vue';
import { useRouter } from "vue-router";
import { computed } from 'vue';

    export default {
           components: {
            AppRegularBtn,
            AppEmailForm,
            AppPopupWarning,
            AppPageLoadingAnimation
        },
        setup(){
            const store = useStore();
            const router = useRouter();

            const dropPassword = () => router.push('/drop-password')
            const loading = reactive({
                isLoading: true
            })
            const warning = reactive({
                text: "",
                isActive: false
            })
            const token = store.state.user.token;
            const email = reactive({
                massagistID: store.state.user.user.id,
                isEmail: false,
                email: computed(() => store.state.user.user.email),
                getNewEmail: async (data) => {
                    loading.isLoading = true;
                    await massagistApi.massagistUpdateData({ id: email.massagistID, token: token, column: data.column, value: data.value })
                        .then(response => console.log(response))
                        .catch(err => console.log(err));
                    await email.getEmail().catch(err => console.log(err));
                },
                getEmail: async () => {
                    await massagistApi.getMassagistInfo({ id: email.massagistID })
                        .then(response => {
                            store.dispatch('setNewUserEmail', response.data.email);
                             const isRememberedUser = JSON.parse(localStorage.getItem('user'));
                             if(isRememberedUser){
                                isRememberedUser.user.email = response.data.email;
                                localStorage.setItem('user', JSON.stringify(isRememberedUser));
                            }
                        })
                        .catch(err => console.log(err))
                    loading.isLoading = false;
                } 

            });

            const password = reactive({
                oldPassword: null,
                newPassword: null,
                newPasswordRepeate: null,
                getOldPassword: (data) => {
                    password.oldPassword = data;
                },
                getNewPassword: (data) => {
                    password.newPassword = data;
                },
                getRepeateNewPassword: (data) => {
                    password.newPasswordRepeate = data;
                },
                saveNewPassword: async () => {
                    loading.isLoading = true;
                    await massagistApi.setNewPassword({ password: password.oldPassword, token: token, newPassword: password.newPassword, massagist_id: email.massagistID })
                        .then(response => {
                            if(response.data === 'Некорректный пароль'){
                                loading.isLoading = false;
                                warning.isActive = true;
                                warning.text = 'Вы ввели некорректный парль. Если вы не помните свой пароль, воспользуйтесь сервисом восстановления паролей'
                                setTimeout(() => {
                                    warning.isActive = false;
                                    warning.text = "";
                                }, 2000)
                            } else {
                                loading.isLoading = false;
                                warning.isActive = true;
                                warning.text = 'Пароль успешно изменен';
                                console.log(response)
                                setTimeout(() => {
                                    warning.isActive = false;
                                    warning.text = "";
                                    password.oldPassword = "";
                                    password.newPassword = "";
                                    password.newPasswordRepeate = "";
                                }, 2000)

                            }
                        })
                        .catch(err => console.log(err))
                    loading.isLoading = false;
                } 
            })

            onMounted(email.getEmail())

                return {
                    email,
                    password,
                    warning,
                    dropPassword,
                    loading
                }
        }
    }
</script>

<style lang="scss" scoped>
    .app-massagist-personal-page-safaty{
        min-height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        padding: 0 0 50px 0;
        .add-email{
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: 100%;
            .input-form{
                width: 80%;
                min-width: 350px;
                max-width: 375px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                padding: 0 1em;
                margin-bottom: 2em;
                .input{
                    margin-top: 0;
                }
                .email-comment{
                    width: 50%;
                    text-align: center;
                    font-weight: 700;
                }
            }
            .button{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                width: 20%;
                min-width: 350px;
                max-width: 375px;
            }
            
        }
        .change-password{
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: 100%;
             .input-form{
                width: 80%;
                min-width: 350px;
                max-width: 375px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                padding: 0 1em;
                h5{
                    font-size: 1.2em;
                    padding: 1em;
                }
                .input{
                    margin-top: 0;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                .email-comment{
                    width: 50%;
                    text-align: center;
                    font-weight: 700;
                }
                .drop-password{
                    font-size: 0.8em;
                    font-weight: 700;
                    color: $accent-dark;
                    cursor: pointer;
                }
            }
            .button{
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                width: 20%;
                min-width: 350px;
                max-width: 375px;
                padding-top: 1em;
            }
        }
    }
</style>