<template>
    <button
        class="app-regular-button"
        :class="{ 'dark' : dark, 'light': light, 'sm': sm, 'disabled': disabled }"
        @click="$emit('action')"
        :disabled="disabled"
        >{{ text }}
        <AppLoadingAnimation v-if="loading" />
    </button>
</template>

<script>
import AppLoadingAnimation from "@/components/animations/app-loading-animation.vue";
    export default {
        components: {
            AppLoadingAnimation
        },
        props: {
            text: {
                type: String,
                default: "Кнопка"
            },
            dark: {
                type: Boolean,
                default: false
            },
            light: {
                type: Boolean,
                default: false
            },
            loading: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            sm: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .app-regular-button{
        padding: 1em 3em;
        font-size: 1em;
        font-weight: 700;
        text-transform: uppercase;
        border-radius: 0.4em;
        position: relative;
        cursor: pointer;
    }
    .light{
        background-color: $white;
        color: $accent-dark;
        border: 2px solid $accent-dark;
        &:focus{
            outline: none;
            text-decoration: underline;
        }
    }
    .dark{
        background-color: $accent-dark;
        color: $white;
        border: transparent;
        &:focus{
            outline: none;
            text-decoration: underline;
        }
    }
    .sm{
        padding: 0.5em 1em!important;
        font-size: 0.8em!important;
        font-weight: 500!important;
    }
    .disabled{
        background-color: transparent;
        color: $disabled;
        border: 1px solid $disabled;
    }
</style>